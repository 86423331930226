import Content from "../components/Content";

const Downloads = () => {
  return (
    <Content>
      Baza
      <ul>
        <li>
          <a href="https://mckpk-my.sharepoint.com/:u:/g/personal/andrzej_kulesza_student_pk_edu_pl/ETUd2sMhVh5IrfIqdxy0hk4BH8A6lN06133te-xpUcpsiw?e=BYzqvx">
            Polska
          </a>{" "}
          (ok 90 MB)
        </li>
        <li>
          <a href="https://mckpk-my.sharepoint.com/:u:/g/personal/andrzej_kulesza_student_pk_edu_pl/ESB6VTKhrvxHqTaLiGlQ4zoBPz_1doR0-3GUFa_9twuKEg?e=Rgf6vs">
            całość
          </a>{" "}
          (ok 2 GB)
        </li>
      </ul>
      Narzędzia
      <ul>
        <li>
          <a href="https://github.com/KulAndy/chess-scrappers">Szperacze</a>
        </li>
      </ul>
    </Content>
  );
};

export default Downloads;
